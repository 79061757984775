import React, {useEffect} from 'react'
import Nav from './nav'

//Language imports
import endata from '../lib/ensite.json';
import pldata from '../lib/plsite.json';


export default function Products() {
  let labels;
  let lang = window.location.pathname.substring(1, 3);
  if (lang == "en") {
    labels = endata.siteGallery;
  } else {
    labels = pldata.siteGallery;
  }
  useEffect(() => {
    document.title = "MetalPegazArt - "+labels.title
  }, [])
  const galleryArray = [
    {id: 1, 
      image: "/imgs/453042662_122104388756410426_4006601689443786457_n.jpg", 
      description: "Messerschmitt Bf 109 w wersji G-2", 
      link: "https://www.facebook.com/metal.pegazart/posts/pfbid02db8knCKru5Lk8rVKr5CqKi55iX8b5xH9Jr8prDJFP175dXNQViiCD9nXwWPPktPVl"},
    {id: 2, 
      image: "/imgs/457736718_122111177120410426_6560587547006156739_n.jpg", 
      description: "Znak z logo", 
      link: "https://www.facebook.com/metal.pegazart/posts/pfbid02aMXfPtZHDiYnwf9EV9n3WgtxRnSC29fKS8R5reGGqkpi8cyhS4cRbwSnNiPoHLQul"},
    {id: 3, 
      image: "/imgs/456510741_122109452756410426_5460444614138823045_n.jpg", 
      description: "Metalowa Róża", 
      link: "https://www.facebook.com/metal.pegazart/posts/pfbid0drLJjTzvdrca9F3X3Rw5nTNvf2E4N5YAUbHidvVbefHkAehAzaMHxudQmXWuEVFMl"},
    {id: 4, 
      image: "/imgs/455993950_122109284852410426_6807567622590828340_n.jpg", 
      description: "NLR Emblem", 
      link: "https://www.facebook.com/metal.pegazart/posts/pfbid0qSCDkH8RxzRqzixWynWANUmuAvgjeRXZDRNR5GGH1tpFfx7mYont1e7p2NKqTn4gl"},
    {id: 5,
      image: "/imgs/460861723_122114125622410426_6007497910914426468_n.jpg",
      description: "Owieczka",
      link: "https://www.facebook.com/metal.pegazart/posts/pfbid02XqCSsxZhMetTE7CdyFSLYTmMjRFLWAnNEcaFbwMEHVRwBeU8XkJxcpf6DzQkfCzMl"},
    {id: 6,
      image: "/imgs/3745095330766358786.jpg",
      description: "Owieczka na allegro",
      link: "https://www.facebook.com/metal.pegazart/posts/pfbid031qi8rKNkj2yuP8PHZpBUnkNdp7id2QG2rcQWuk4U7yS5nNZnbZaxGu8jQJvu6gZAl"},
  ]
  return (
    <div className={"root"}>
      <div className="header">
        <Nav />
        <h1 className='header-title'>MetalPegazArt</h1>
        <h2>{labels.heading}</h2>
      </div>
      <div className="content">
        <div className="card-container">
        {galleryArray.map((item) => (
          <a href={""+item.link} key={item.id} target="_blank" rel="noreferrer" className={'product-card'}>
            <h2 style={{"display": "none"}} className={'product-label'}>New</h2>
            <img
                src={item.image}
                alt="product"
                className={'product-img'}
            />
            <h2 className={'product-card-container'}>{item.description}</h2>
          </a>
        ))}
        </div>
      </div>
    </div>
  )
}